import { gql } from "@apollo/client";

export const GET_DEV_CENTER_ACCOUNT = gql`
  query GetDevCenterAccount {
    devCenterAccount {
      currentDevCenterUser {
        id
        isAccountOwner
      }
    }
  }
`;
