import React, { ComponentType } from "react";
import { navigate } from "gatsby";
import { Spinner } from "@jobber/components/Spinner";
import { useQuery } from "@apollo/client";
import { LoginStates, useUserContext } from "@/context/user";
import { safelyGetLocationPathname } from "@/utils/location";
import { GetDevCenterAccountQuery } from "@/utils/graphql/types";
import { GET_DEV_CENTER_ACCOUNT } from "./withAuthentication.graphql";

/**
 * The `withAuthentication` component is a higher-order component which will provide authentication to a page.
 * When a page is enhanced with this HOC, it will check if the user is logged in, or redirect to our login page.
 */
export function withAuthentication<P extends object>(
  Component: ComponentType<P>,
) {
  return AuthenticatedComponent;

  // eslint-disable-next-line max-statements
  function AuthenticatedComponent(props: P) {
    const { state, MFAEnabled } = useUserContext();
    const { data, loading, startPolling, stopPolling } =
      useQuery<GetDevCenterAccountQuery>(GET_DEV_CENTER_ACCOUNT);
    const from = safelyGetLocationPathname();

    if (state == LoginStates.LoggedOut) {
      navigate("/landing", { state: { from } });

      return null;
    }

    // This is added to prevent the page from infinite loading
    // We noticed that sometimes this query fails, apparently because of a race condition
    // This isn't good, we need to figure something out to prevent this from happening
    startPolling(500);

    if (
      state == LoginStates.Loading ||
      loading ||
      !data?.devCenterAccount.currentDevCenterUser
    ) {
      return (
        <div>
          <Spinner />
        </div>
      );
    }

    stopPolling();

    if (
      !data.devCenterAccount.currentDevCenterUser.isAccountOwner &&
      !MFAEnabled &&
      from !== "/settings/mfa_setup"
    ) {
      navigate("/settings/mfa_setup", { state: { from } });

      return null;
    }

    return <Component {...props} />;
  }
}
