import React, { useState } from "react";
import { Auth } from "@aws-amplify/auth";
import { showToast } from "@jobber/components/Toast";
import { Content } from "@jobber/components/Content";
import { ErrorCodes } from "@/utils/cognito/ErrorCodes";
import { useDisplayError } from "@/hooks/useDisplayError";
import { ForgotPassword } from "./ForgotPassword";

interface ForgotPasswordLoaderProps {
  defaultEmail?: string;
}

export function ForgotPasswordLoader({
  defaultEmail,
}: ForgotPasswordLoaderProps) {
  const [isEmailSent, setIsEmailSent] = useState(false);
  const { displayError } = useDisplayError();

  return (
    <Content>
      <ForgotPassword
        isEmailSent={isEmailSent}
        onSendPasswordRecoveryEmailRequest={onSendPasswordRecoveryEmailRequest}
        defaultEmail={defaultEmail}
      />
    </Content>
  );

  async function onSendPasswordRecoveryEmailRequest(email: string) {
    try {
      await Auth.forgotPassword(email);
      showToast({ message: "Password reset email sent", variation: "success" });
      setIsEmailSent(true);
    } catch (error: any) {
      const code = error?.code as undefined | ErrorCodes;
      setIsEmailSent(false);
      switch (code) {
        case ErrorCodes.USER_NOT_FOUND: {
          displayError([
            "No associated account found. Double check your email.",
          ]);
          break;
        }
        default: {
          displayError([error.message]);
        }
      }
    }
  }
}
