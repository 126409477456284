import React from "react";
import { PageProps } from "gatsby";
import { ForgotPassword } from "@/features/Authentication/ForgotPassword";
import { Page } from "@/components/Page";
import { withoutAuthentication } from "@/features/Authentication/AccessRestriction";

interface ForgotPasswordPageLocationState {
  defaultEmail?: string;
}

function ForgotPasswordPage({
  location: { state },
}: PageProps<unknown, unknown, ForgotPasswordPageLocationState | undefined>) {
  const defaultEmail = state?.defaultEmail;

  return (
    <Page variation="presession">
      <ForgotPassword defaultEmail={defaultEmail} />
    </Page>
  );
}

export default withoutAuthentication(ForgotPasswordPage);
