import React, { ComponentType } from "react";
import { navigate } from "gatsby";
import { Spinner } from "@jobber/components/Spinner";
import { LoginStates, useUserContext } from "@/context/user";

/**
 * The `withoutAuthentication` component is a higher-order component which will ensure that a user that is authenticated cannot visit a page.
 * When a page is enhanced with this HOC, if the user is logged in, it will redirect to the root page (`/`)
 */
export function withoutAuthentication<P extends object>(
  Component: ComponentType<P>,
) {
  return AuthenticatedComponent;

  function AuthenticatedComponent(props: P) {
    const { state } = useUserContext();
    if (state == LoginStates.Loading) {
      return (
        <div>
          <Spinner />
        </div>
      );
    }

    if (state == LoginStates.LoggedIn) {
      navigate("/");
      return null;
    }

    return <Component {...props} />;
  }
}
