import React from "react";
import * as styles from "./GoogleFontLabel.module.css";

interface GoogleFontLabelProps {
  label: string;
  variation?: "light" | "highlight";
}

export function GoogleFontLabel({
  label,
  variation = "highlight",
}: GoogleFontLabelProps) {
  const color =
    variation == "light"
      ? `var(--color-blue--light)`
      : `var(--color-brand--highlight)`;

  return (
    <span
      className={styles.container}
      style={{
        color: color,
      }}
    >
      <link
        href="https://fonts.googleapis.com/css2?family=IBM+Plex+Mono&display=swap"
        rel="stylesheet"
      />
      {label}
    </span>
  );
}
