import React from "react";
import { Text } from "@jobber/components/Text";
import { Link } from "@/components/Link";

export function AlreadyHavePasswordPrompt() {
  return (
    <Text>
      Already have your password? <Link to="/login">Log in</Link>
    </Text>
  );
}
