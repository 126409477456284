import React from "react";
import {
  Link as InternalLink,
  GatsbyLinkProps as InternalLinkProps,
} from "gatsby";
import * as styles from "./Link.module.css";

type LinkProps = Omit<
  InternalLinkProps<Record<string, unknown>>,
  "ref" | "className"
>;

export function Link(props: LinkProps) {
  return <InternalLink className={styles.link} {...props} />;
}
