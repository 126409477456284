import React, { useState } from "react";
import { InputText } from "@jobber/components/InputText";
import { Text } from "@jobber/components/Text";
import { Divider } from "@jobber/components/Divider";
import { Content } from "@jobber/components/Content";
import { Heading } from "@jobber/components/Heading";
import { Button } from "@jobber/components/Button";
import { Form } from "@jobber/components/Form";
import * as styles from "@/features/Authentication/PresessionCommon/PresessionCommon.module.css";
import { Banner } from "@/components/Banner";
import { validEmail } from "@/utils/email";
import { AlreadyHavePasswordPrompt } from "../components/AlreadyHavePasswordPrompt";
import { JobberDevCenterLabel } from "../components/JobberDevCenterLabel";

interface ForgotPasswordProps {
  isEmailSent: boolean;
  defaultEmail?: string;
  onSendPasswordRecoveryEmailRequest(email: string): void;
}

export function ForgotPassword({
  isEmailSent,
  defaultEmail,
  onSendPasswordRecoveryEmailRequest,
}: ForgotPasswordProps) {
  const initialEmail = defaultEmail || "";
  const [email, setEmail] = useState(initialEmail);

  return (
    <div className={styles.common_wrapper}>
      <Banner />
      <JobberDevCenterLabel />
      {isEmailSent ? (
        <Content>
          <Heading level={2}>Email Sent</Heading>
          <Text>Check your email to complete resetting your password.</Text>
          <Divider />
          <AlreadyHavePasswordPrompt />
        </Content>
      ) : (
        <Form onSubmit={handleSendPasswordRecoveryEmailRequest}>
          <Content>
            <Heading level={2}>Forgot Password</Heading>
            <Text>
              {
                "Enter the email you used to sign up and we'll send you instructions to reset your password."
              }
            </Text>
            <div className={styles.validation_message}>
              <InputText
                name="email"
                placeholder="Email"
                defaultValue={defaultEmail}
                size="large"
                validations={{
                  required: {
                    value: true,
                    message: "Please provide an email address",
                  },
                  validate: (value: string) => {
                    if (validEmail(value)) {
                      return "You must provide a valid email address";
                    }
                  },
                }}
                onChange={(value: string) => setEmail(value)}
              />
            </div>
            <Button label="Send Recovery Email" size="large" fullWidth submit />
            <Divider />
            <AlreadyHavePasswordPrompt />
          </Content>
        </Form>
      )}
    </div>
  );

  function handleSendPasswordRecoveryEmailRequest() {
    onSendPasswordRecoveryEmailRequest(email);
  }
}
